import React from 'react'

const OverviewSvg = () => {
    return (
        <svg viewBox="0 0 29.5 29.5">
            <g transform="translate(-173.25 -173.25)">
                <g  transform="translate(173.25 173.25)">
                    <path d="M202.09,187.34H188.66V173.91a.661.661,0,0,0-.66-.66A14.75,14.75,0,1,0,202.75,188,.661.661,0,0,0,202.09,187.34Z" transform="translate(-173.25 -173.25)" fill="#ccced6"/>
                </g>
                <g id="IconHover" transform="translate(190.635 173.365)">
                    <path d="M192.3,185.7a.616.616,0,0,0,.087.139.553.553,0,0,0,.045.072l.006,0a.653.653,0,0,0,.473.2h10.7a.661.661,0,0,0,.66-.66A11.373,11.373,0,0,0,192.91,174.1a.661.661,0,0,0-.66.66v6.278a.526.526,0,0,0,0,.053v4.37a.58.58,0,0,0,.019.1A.6.6,0,0,0,192.3,185.7Z" transform="translate(-192.249 -174.099)" fill="#545454"/>
                </g>
            </g>
        </svg>
    )
}

export default OverviewSvg
