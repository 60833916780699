import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import WhiteLogo from '../../assets/images/white-logo.png'

const Left = () => {
    return (
        <div className='left'>
            <Container>
                <Row className='justify-content-center'>
                    <Col lg={10}>
                        <img className='mt-5 mb-5 logo' src={WhiteLogo} />
                        <div className='mt-5'>
                            <h1 className='text-semibold text-white'><b>A few steps away from your management system</b></h1>
                            <ul className="LoginListing mt-2">
                                <li className='text-regular'>No credit card</li>
                                <li className='text-regular'>Manage all the tasks</li>
                                <li className='text-regular'>Check out all premium features</li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Left