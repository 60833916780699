import React from 'react'

const MeetingCard = ({title, time}) => {
    return (
        <div className='border-bottom pb-3 mt-3'>
            <h6 className='text-medium mb-1'>{title}</h6>
            <small className="pink text-medium radius-20">{time}</small>
        </div>
    )
}

export default MeetingCard