import React from 'react'

const InvoiceBoardSvg = () => {
    return (
        <svg viewBox="0 0 30.686 25.5">
            <g transform="translate(-43.407 -478)">
                <path d="M270.288,43.572h-2.809V39.9a.649.649,0,0,0-.648-.648H240.9a.649.649,0,0,0-.648.648v21.22a3.636,3.636,0,0,0,3.631,3.631h23.424a3.636,3.636,0,0,0,3.631-3.631V44.22A.649.649,0,0,0,270.288,43.572Z" transform="translate(-196.843 438.75)" fill="#ccced6"/>
                <g id="OnHover">
                    <path d="M264.915,62.25H245.9a.648.648,0,0,0,0,1.3h19.017a.648.648,0,0,0,0-1.3Z" transform="translate(-197.521 435.631)" fill="#545454"/>
                    <path d="M267.409,54.25h-5.036c-.232,0-.419.29-.419.648s.188.648.419.648h5.036c.232,0,.419-.29.419-.648S267.641,54.25,267.409,54.25Z" transform="translate(-199.786 436.716)" fill="#545454"/>
                    <path d="M266.678,58.25H258.9a.648.648,0,0,0,0,1.3h7.78a.648.648,0,1,0,0-1.3Z" transform="translate(-199.284 436.174)" fill="#545454"/>
                </g>
                <g id="IconHover" transform="translate(46.864 481.241)">
                    <circle id="Ellipse_5" data-name="Ellipse 5" cx="5.954" cy="5.954" r="5.954" fill="#545454"/>
                </g>
                <path d="M250.168,47.257h2.443a.458.458,0,0,0,0-.916h-1.374v-.763a.458.458,0,1,0-.915,0v.763h-.153a1.679,1.679,0,0,0,0,3.358h1.221a.764.764,0,0,1,0,1.527h-2.443a.458.458,0,0,0,0,.915h1.374V52.9a.458.458,0,1,0,.915,0v-.763h.153a1.679,1.679,0,1,0,0-3.358h-1.221a.763.763,0,0,1,0-1.527Z" transform="translate(-197.96 437.954)" fill="#dbdbdb"/>
            </g>
        </svg>
    )
}

export default InvoiceBoardSvg
