import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { success, error } from '../../msg';
import { ToastContainer } from 'react-toastify';
import { PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH, OLD_PASSWORD_REQUIRED, NEW_PASSWORD_REQUIRED, CONFIRM_PASSWORD_REQUIRED } from '../../constants'

const PasswordForm = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const password = watch("newPassword");
    const onSubmit = (data) => {
        // e.preventDefault()
        success("Password updated Successfully")
        setTimeout(() => {
            navigate('/password');
        }, 1500);
    }

    return (
        <>
            <ToastContainer />
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3 mx-1 w-100 w-md-50" controlId="oldPassword">
                    <Form.Control
                        type="password"
                        name="oldPassword"
                        placeholder="Old Password"
                        {...register("oldPassword",
                            {
                                minLength: {
                                    value: 8,
                                    message: PASSWORD_MIN_LENGTH
                                },
                                maxLength: {
                                    value: 30,
                                    message: PASSWORD_MAX_LENGTH
                                },
                                required: {
                                    value: true,
                                    message: OLD_PASSWORD_REQUIRED
                                },
                            })
                        }
                    />
                    {errors.oldPassword && <small className='error-setting'>{errors.oldPassword.message}</small>}
                </Form.Group>

                <Form.Group className="mb-3 mx-1 w-100 w-md-50" controlId="newPassword">
                    <Form.Control
                        type="password"
                        name="newPassword"
                        placeholder="New Password"
                        {...register("newPassword",
                            {
                                minLength: {
                                    value: 8,
                                    message: PASSWORD_MIN_LENGTH
                                },
                                maxLength: {
                                    value: 30,
                                    message: PASSWORD_MAX_LENGTH
                                },
                                required: {
                                    value: true,
                                    message: NEW_PASSWORD_REQUIRED
                                },
                            })
                        }
                    />
                    {errors.newPassword && <small className='error-setting'>{errors.newPassword.message}</small>}
                </Form.Group>

                <Form.Group className="mb-3 mx-1 w-100 w-md-50" controlId="confirmPassword">
                    <Form.Control
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        {...register("confirmPassword",
                            {
                                minLength: {
                                    value: 8,
                                    message: PASSWORD_MIN_LENGTH
                                },
                                maxLength: {
                                    value: 30,
                                    message: PASSWORD_MAX_LENGTH
                                },
                                required: {
                                    value: true,
                                    message: CONFIRM_PASSWORD_REQUIRED
                                },
                                validate: (value) =>
                                    value === password || "The Passwords do not match"
                            })
                        }
                    />
                    {errors.confirmPassword && <small className='error-setting'>{errors.confirmPassword.message}</small>}
                </Form.Group>

                <div className="d-flex justify-content-end">
                    <Button className="orange btns btn-save" type="submit">Save</Button>
                </div>
            </Form>

        </>
    )
}

export default PasswordForm