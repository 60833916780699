import React, { useState } from 'react'
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { useNavigate, NavLink } from 'react-router-dom';
import { EMAIL_MAX_LENGTH,EMAIL_REQUIRED,EMAIL_FORMAT,PASSWORD_MIN_LENGTH,PASSWORD_MAX_LENGTH,PASSWORD_REQUIRED } from '../../constants'
import {success,error} from '../../msg';
import { ToastContainer } from 'react-toastify';

const SignInForm = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [hide, setHide] = useState(true);
    const navigate = useNavigate();

    const onSubmit = (data) => {
        success("Signin Successfull")
        setTimeout(() => {
            navigate('/');
        }, 1500);
    }

    return (
        <div className='forms'>
            <ToastContainer/>
            <Container>
                <Row className='justify-content-center'>
                    <Col lg={10}>
                        <div className="text-center">
                            <h1 className='text-semibold'>Sign In</h1>
                            <p className='text-medium text-muted'>Let's get you all set up so you can verify your personal account <br /> and begin setting up your profile</p>
                        </div>

                        <Form onSubmit={handleSubmit(onSubmit)} className="mt-4">
                            <Row className="justify-content-center">
                                <Col sm={7}>
                                    <Form.Group controlId="formEmail">
                                        <Form.Control
                                            className="input mb-2"
                                            type="email"
                                            name="email"
                                            placeholder="Enter email"
                                            {...register("email",
                                                {
                                                    maxLength: {
                                                        value: 256,
                                                        message: EMAIL_MAX_LENGTH
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: EMAIL_REQUIRED
                                                    },
                                                    pattern: {
                                                        value: /\S+@\S+\.\S+/,
                                                        message: EMAIL_FORMAT
                                                    }
                                                })
                                            }
                                        />
                                    </Form.Group>
                                    {errors.email && <small className='error'>{errors.email.message}</small>}
                                </Col>
                                <Col sm={7}>
                                    <InputGroup>
                                        <Form.Control
                                            className='input mb-2 password-field'
                                            type={hide ? "password" : "text"}
                                            name="password"
                                            placeholder="Password"
                                            {...register("password",
                                                {
                                                    minLength: {
                                                        value: 8,
                                                        message: PASSWORD_MIN_LENGTH
                                                    },
                                                    maxLength: {
                                                        value: 30,
                                                        message: PASSWORD_MAX_LENGTH
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: PASSWORD_REQUIRED
                                                    },
                                                })
                                            }
                                        />
                                        <InputGroup.Text>
                                            {
                                                hide ? <BsEyeFill onClick={() => setHide(false)} className='eye-icon' /> : <BsEyeSlashFill onClick={() => setHide(true)} className='eye-icon' />
                                            }
                                        </InputGroup.Text>
                                    </InputGroup>
                                    {errors.password && <small className='error'>{errors.password.message}</small>}
                                </Col>
                                <Col sm={7}>
                                    <p><NavLink className='text-orange' to="/loginverification">Forgot Password</NavLink></p>
                                </Col>
                                <Col sm={7} md={7} lg={7}>
                                    <Button className="btn-submit mt-4 w-100" type="submit">
                                        Sign In
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SignInForm
