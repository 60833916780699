import React from 'react'
import { Outlet } from 'react-router-dom'
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import Heading from '../components/Heading';
import ActivityCard from '../components/RewardsAndPointsComponents/ActivityCard';
import CarouselCard from '../components/RewardsAndPointsComponents/CarouselCard';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

const RewardsAndPoints = () => {
    ChartJS.register(ArcElement, Tooltip, Legend);
    const dummyData = {
        labels: ['Total Points', 'Current Points'],
        datasets: [
            {
                data: [8000, 10000],
                backgroundColor: [
                    'rgba(230, 1, 6, 1)',
                    'rgba(14, 58, 79, 1)',
                ],
                borderColor: [
                    'rgba(230, 1, 6, 1)',
                    'rgba(14, 58, 79, 1)',
                ],
                borderWidth: 1,
                borderRadius: 0,
                hoverOffset: 4
            },
        ],
    };
    return (
        <div className="RewardsAndPointPage Dashboard mt-4">
            <Container>
                <Row>
                    <Col xs={12}>
                        <Heading heading="Rewards and Points" subheading="Here you can manage all your rewards and points" />
                    </Col>

                    <Col xs={12}>
                        <div className='bg-light radius-20 w-100 mt-3 p-4'>
                            <h5 className='text-semibold'>Rewards and Points</h5>
                            <Row className='mt-3'>
                                <Col lg={6}>
                                    <div className='mb-3' style={{ maxWidth: "500px", maxHeight: "500px" }}>
                                        <Doughnut data={dummyData} />
                                    </div>
                                </Col>

                                <Col lg={6} className="my-auto">
                                    <Carousel>
                                        <Carousel.Item className='carousel-item'>
                                            <CarouselCard points="6,250" posts="5450" />
                                        </Carousel.Item>
                                        <Carousel.Item className='carousel-item'>
                                            <CarouselCard points="6,250" posts="5450" />
                                        </Carousel.Item>
                                        <Carousel.Item className='carousel-item'>
                                            <CarouselCard points="6,250" posts="5450" />
                                        </Carousel.Item>
                                    </Carousel>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <div className='mt-3'>
                            <h5 className='text-semibold'>Point Acquisition Activity</h5>
                        </div>
                    </Col>

                    <Col xs={12} md={6}>
                        <ActivityCard points="4,500" title="Clutch Review" />
                    </Col>

                    <Col xs={12} md={6}>
                        <ActivityCard points="4,500" title="Clutch Review" />
                    </Col>
                </Row>
            </Container>
            <Outlet />
        </div>
    )
}

export default RewardsAndPoints;

