import React from 'react'

const MeetingBoardSvg = () => {
    return (
        <svg viewBox="0 0 31.767 27.5">
            <g  transform="translate(-308.25 -308.25)">
                <rect id="IconHover" width="4.027" height="6.585" rx="0.75" transform="translate(312.724 308.25)" fill="#545454"/>
                <path d="M320.921,313.412h9.843a.587.587,0,1,0,0-1.162h-9.843a.587.587,0,1,0,0,1.162Z" transform="translate(-1.262 -0.901)" fill="#ccced6"/>
                <rect id="IconHover" width="4.027" height="6.585" rx="0.75" transform="translate(331.516 308.25)" fill="#545454"/>
                <path d="M339.346,312.25h-1.79a.587.587,0,1,0,0,1.162h1.118v5.035H309.592v-5.035h1.118a.587.587,0,1,0,0-1.162h-1.79a.631.631,0,0,0-.671.581V336.07a.631.631,0,0,0,.671.581h30.424a.631.631,0,0,0,.671-.581V312.831A.631.631,0,0,0,339.346,312.25Z" transform="translate(0 -0.901)" fill="#ccced6"/>
                <rect id="IconHover" width="5.816" height="4.261" rx="0.75" transform="translate(321.673 321.419)" fill="#545454"/>
                <rect id="IconHover" width="5.816" height="4.261" rx="0.75" transform="translate(312.724 321.419)" fill="#545454"/>
                <rect id="IconHover" width="5.816" height="4.261" rx="0.75" transform="translate(329.726 327.616)" fill="#545454"/>
                <rect id="IconHover" width="5.816" height="4.261" rx="0.75" transform="translate(329.726 321.419)" fill="#545454"/>
                <rect id="IconHover" width="5.816" height="4.261" rx="0.75" transform="translate(312.724 327.616)" fill="#545454"/>
            </g>
        </svg>
    )
}

export default MeetingBoardSvg
