import React from 'react'
import { Navigate , Route, Outlet } from "react-router-dom";
import SideNav from '../components/SideNav'
import Header from '../components/Header'

function DashboardLayout({children}) {
    return (
        <React.Fragment>
            <div className="layout_wrapper">
                <div className="PageWrap">
                    <SideNav/>
                    <div className="MainBody">
                        <Header/>
                        <div id="user-content-wrapper">
                            <main>{children}</main>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default DashboardLayout
