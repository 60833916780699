import React, { useContext, useLayoutEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import OverviewSvg from '../components/SideNavSvgs/overview';
import MarketingBoardSvg from '../components/SideNavSvgs/MarketingBoard';
import AppBoardSvg from '../components/SideNavSvgs/AppBoard';
import MeetingBoardSvg from '../components/SideNavSvgs/MeetingBoard';
import InvoiceBoardSvg from '../components/SideNavSvgs/InvoiceBoard';
import RewardsAndPointsSvg from '../components/SideNavSvgs/RewardsAndPoints';
import DownloadableContentSvg from '../components/SideNavSvgs/DownloadableContent';
import SettingsSvg from '../components/SideNavSvgs/Settings';
import { Toggle } from '../App';
import { GiHamburgerMenu } from 'react-icons/gi';

const SvgSmall = () => {
    return (
        <svg x="0px" y="0px" viewBox="0 0 38 30">
            <g id="Group_6801" transform="translate(4097.452 -4162.176)">
                <path id="Path_69" fill="#EF5123" d="M-4079.7,4162.9v11.4c-0.2,0.4-5.9,13.2-16.3,15.5L-4079.7,4162.9z" />
                <path id="Path_70" fill="#F37A20" d="M-4061.5,4189.9c-10.4-2.4-16.1-15.1-16.3-15.7l0-11.3L-4061.5,4189.9z" />
                <g id="Group_73" transform="translate(-4092.221 4197.809)">
                    <g id="Group_72" transform="translate(0 0)">
                        <g id="Group_71">
                            <g id="Group_70">
                                <path id="Path_71" fill="#EE3824" d="M8.1-11.9h10.8l8.5,5.2L13.5-6.8L-0.4-6.7L8.1-11.9z" />
                            </g>
                        </g>
                    </g>
                </g>
                <path id="Path_72" d="M-4082.8,4184.2c-0.2,0-0.4-0.2-0.4-0.4c0-0.1,0-0.2,0.1-0.2l4.4-6.2c0.1-0.1,0.2-0.2,0.3-0.2h0
                    c0.1,0,0.3,0.1,0.3,0.2l3.7,6.2c0.1,0.1,0.1,0.3,0,0.4c-0.1,0.1-0.2,0.2-0.3,0.2L-4082.8,4184.2z"/>
            </g>
        </svg>
    )
}

const SvgLg = () => {
    return (
        <svg version="1.1" id="BrandLogo" x="0px" y="0px" viewBox="0 0 300 44">
            <g className="BrandIcon">
                <path className="st0" d="M270.4,0.3v17.6c-0.3,0.7-9,20.3-25.1,23.9L270.4,0.3z" />
                <path className="st1" d="M298.5,41.8c-16.1-3.7-24.7-23.2-25.1-24.1V0.3L298.5,41.8z" />
                <polygon className="st2" points="263.6,35.7 280.2,35.7 293.3,43.7 271.9,43.6 250.6,43.7 " />
                <path className="st3" d="M265.7,33.1c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.2-0.1-0.5,0-0.7l6.8-9.5c0.1-0.2,0.3-0.3,0.5-0.3l0,0
                    c0.2,0,0.4,0.1,0.5,0.3l5.7,9.5c0.1,0.2,0.1,0.4,0,0.6c-0.1,0.2-0.3,0.3-0.5,0.3L265.7,33.1z"/>
            </g>
            <path className="st3" d="M114.6,38.8h-7.9L94.4,25h14.9c1,0,1.6-0.9,1.6-2.5s-0.5-2.6-1.6-2.6H91.3v19h-5.8V14.1h24
                c2.1-0.1,4,0.9,5.3,2.6c1.2,1.7,1.8,3.7,1.8,5.7c0.1,2.1-0.6,4.1-1.8,5.7c-1.2,1.7-3.2,2.7-5.3,2.6h-2.2L114.6,38.8z"/>
            <path className="st3" d="M146.5,19.9h-20.7v3.7h19.3v5.8h-19.3v3.7h20.6v5.8H120V14.1h26.4L146.5,19.9z" />
            <path className="st3" d="M178.5,14.1l-12.6,24.8H161l-12.6-24.8h6.5l8.7,17l8.6-17L178.5,14.1z" />
            <path className="st3" d="M211.9,31c0,2.1-0.8,4.2-2.4,5.6c-1.5,1.5-3.6,2.3-5.7,2.2h-14.5c-2.1,0-4.2-0.8-5.7-2.2
                c-1.5-1.4-2.4-3.5-2.3-5.6v-9.1c-0.1-2.1,0.8-4.2,2.4-5.6c1.5-1.5,3.6-2.3,5.7-2.2h14.5c2.1,0,4.2,0.8,5.7,2.2
                c1.6,1.4,2.4,3.5,2.4,5.6V31z M206.1,31v-9.1c0-1.4-0.8-2.1-2.3-2.1h-14.5c-1.5,0-2.3,0.7-2.3,2.1V31c0,1.4,0.8,2.1,2.3,2.1h14.5
                C205.4,33.1,206.1,32.4,206.1,31z"/>
            <path className="st3" d="M240.1,38.8h-24.8V14.1h5.8v19h19L240.1,38.8z" />
            <path className="st3" d="M29.6,19.9H18.5v19h-5.8v-19H1.5v-5.8h28.1V19.9z" />
            <path className="st3" d="M59.1,19.9H38.5v3.7h19.3v5.8H38.5v3.7h20.6v5.8H32.7V14.1h26.4V19.9z" />
            <polygon className="st0" points="72.8,13.3 62.2,26.2 73.9,38.9 82.3,38.9 70.9,25.6 80.6,13.3 " />
        </svg>
    )
}

const SideNav = () => {
    const [toggle, setToggle] = useContext(Toggle);
    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }

    const [width, height] = useWindowSize();
    const handleToggle = ()=>{
        console.log(width)
        if(width<=850){
            setToggle(!toggle);
        }
    }

    return (
        <aside className={`show ${!toggle && "half"}`}>
            <div className='d-flex justify-content-between'>
                <div className="brand_logo">
                    {
                        toggle ? <SvgLg /> : <SvgSmall />
                    }

                </div>
                <GiHamburgerMenu style={{ cursor: 'pointer' }} onClick={() => setToggle(!toggle)} className='me-4 toggle-icon' />
            </div>
            <div className="SideNav">
                <nav>
                    <ul>
                        <li>
                            <NavLink onClick={()=>handleToggle()} exact to="/" activeClassName="active"><OverviewSvg /><span className={`${(toggle === true) ? "NavLinkText" : "NavLinkText half d-none"}`}>Overview</span></NavLink>
                        </li>
                        <li>
                            <NavLink onClick={()=>handleToggle()} exact to="/marketingboard" activeClassName="active"><MarketingBoardSvg /><span className={`${(toggle === true) ? "NavLinkText" : "NavLinkText half d-none"}`}>Marketing Board</span></NavLink>
                        </li>
                        <li>
                            <NavLink onClick={()=>handleToggle()} exact to="/appboard" activeClassName="active"><AppBoardSvg /><span className={`${(toggle === true) ? "NavLinkText" : "NavLinkText half d-none"}`}>App Board</span></NavLink>
                        </li>
                        <li>
                            <NavLink onClick={()=>handleToggle()} exact to="/meetingboard" activeClassName="active"><MeetingBoardSvg /><span className={`${(toggle === true) ? "NavLinkText" : "NavLinkText half d-none"}`}>Meeting Board</span></NavLink>
                        </li>
                        <li>
                            <NavLink onClick={()=>handleToggle()} exact to="/invoiceboard" activeClassName="active"><InvoiceBoardSvg /><span className={`${(toggle === true) ? "NavLinkText" : "NavLinkText half d-none"}`}>Invoice Board</span></NavLink>
                        </li>
                        <li>
                            <NavLink onClick={()=>handleToggle()} exact to="/rewardsandpoints" activeClassName="active"><RewardsAndPointsSvg /><span className={`${(toggle === true) ? "NavLinkText" : "NavLinkText half d-none"}`}>Rewards and Points</span></NavLink>
                        </li>
                        <li>
                            <NavLink onClick={()=>handleToggle()} exact to="/downloadablecontent" activeClassName="active"><DownloadableContentSvg /><span className={`${(toggle === true) ? "NavLinkText" : "NavLinkText half d-none"}`}>Downloadable Content</span></NavLink>
                        </li>
                        <li>
                            <NavLink onClick={()=>handleToggle()} exact to="/profile" activeClassName="active"><SettingsSvg /><span className={`${(toggle === true) ? "NavLinkText" : "NavLinkText half d-none"}`}>Settings</span></NavLink>
                        </li>
                        {/* <li>
                            <Link exact to="/profile" activeClassName="active" className=""><SettingsSvg/><span className="NavLinkText">Settings</span></Link>
                        </li> */}
                    </ul>
                </nav>
            </div>
        </aside>
    )
}

export default SideNav;
