import React from 'react'

const RewardsAndPointsSvg = () => {
    return (
        <svg viewBox="0 0 30.686 24.471">
            <g transform="translate(-34.25 -240.25)">
                <path d="M40.465,265.031a.583.583,0,1,0,1.165,0v-.777a.58.58,0,0,0-.171-.412L37.3,259.679a6.468,6.468,0,0,1-1.881-4.54v-8.752a.971.971,0,0,1,1.942,0l.779,8.546c0,.322.173.194.495.194s.384-.218.384-.539l-.493-8.2a2.136,2.136,0,1,0-4.273,0v8.752a7.642,7.642,0,0,0,2.222,5.364l3.993,3.992Z" transform="translate(0 -0.893)" fill="#ccced6"/>
                <path d="M56.421,262.709a.58.58,0,0,0-.171.412v4.661a.583.583,0,1,0,1.165,0v-4.42l5.334-5.334a.974.974,0,0,1,1.374,0l.134.134a.973.973,0,0,1,0,1.374L60.694,263.1a.582.582,0,0,0,.823.823l3.563-3.563a2.138,2.138,0,0,0,0-3.02l-.134-.134a2.137,2.137,0,0,0-3.02,0Z" transform="translate(-4.909 -3.644)" fill="#ccced6"/>
                <path d="M40.2,260.359l3.563,3.563a.582.582,0,1,0,.823-.823l-3.563-3.563a.973.973,0,0,1,0-1.374l.134-.134a.974.974,0,0,1,1.374,0l5.334,5.334v4.42a.583.583,0,1,0,1.165,0v-4.661a.58.58,0,0,0-.171-.412l-5.5-5.5a2.137,2.137,0,0,0-3.02,0l-.134.134A2.137,2.137,0,0,0,40.2,260.359Z" transform="translate(-1.189 -3.644)" fill="#ccced6"/>
                <path d="M69.494,244.25a2.138,2.138,0,0,0-2.136,2.136l-.626,8.067c0,.322-.244.211.078.211s.527.956.527.635l1.187-8.912a.971.971,0,0,1,1.942,0v8.752a6.468,6.468,0,0,1-1.881,4.54l-4.163,4.164a.58.58,0,0,0-.171.412v.777a.583.583,0,1,0,1.165,0V264.5l3.993-3.992a7.642,7.642,0,0,0,2.222-5.364v-8.752A2.138,2.138,0,0,0,69.494,244.25Z" transform="translate(-6.694 -0.893)" fill="#ccced6"/>
                <g id="IconHover" transform="translate(42.019 240.25)">
                    <circle cx="7.574" cy="7.574" r="7.574" fill="#545454"/>
                    <circle cx="6.409" cy="6.409" r="6.409" transform="translate(1.165 1.165)" fill="#545454"/>
                </g>
                <path d="M52.386,245.969h3.107a.583.583,0,1,0,0-1.165H53.746v-.971a.583.583,0,1,0-1.165,0v.971h-.194a2.136,2.136,0,1,0,0,4.273H53.94a.971.971,0,1,1,0,1.942H50.833a.583.583,0,1,0,0,1.165h1.748v.971a.583.583,0,0,0,1.165,0v-.971h.194a2.136,2.136,0,1,0,0-4.273H52.386a.971.971,0,1,1,0-1.942Z" transform="translate(-3.57 -0.669)" fill="#ccced6"/>
                <path d="M38.368,245.654l-2.33-.512-.789,2.046.189,8.313.41,2.078.96,1.6,4.279,4.476V265.8h6.5l.2-5.787-3.452-2.4-1.42-2.014-1.736-1.471-.536.352-.442.29-.568.187h-.61Z" transform="translate(-0.223 -1.092)" fill="#ccced6"/>
                <path d="M66.152,245.759l2.263-.51.766,2.038L69,255.567l-.4,2.07-.932,1.592-4.156,4.458v2.134H57.2l-.2-5.764,3.353-2.388,1.379-2.006,1.686-1.465.521.35.429.289.552.186h.592Z" transform="translate(-5.076 -1.116)" fill="#ccced6"/>
            </g>
        </svg>
    )
}

export default RewardsAndPointsSvg
