import React from 'react'

const DownloadableContentSvg = () => {
    return (
        <svg viewBox="0 0 26.025 24.471">
            <g transform="translate(-33.125 -174.25)">
                <path d="M2.355,0H23.671a2.354,2.354,0,0,1,2.354,2.354V22.117a2.355,2.355,0,0,1-2.355,2.355H2.355A2.355,2.355,0,0,1,0,22.117V2.355A2.355,2.355,0,0,1,2.355,0Z" transform="translate(33.125 174.25)" fill="#ccced6"/>
                <g id="IconHover">
                    <circle cx="4.978" cy="4.978" r="4.978" transform="translate(41.16 184.81)" fill="#545454"/>
                </g>
                <g id="Group_58" transform="translate(42.253 174.25)">
                    <g id="IconHover" transform="translate(0)">
                        <path d="M52.644,182.6l-2.331-1.67-1.554,1.67-1.554-1.67-2.331,1.67V174.25h7.769Z" transform="translate(-44.875 -174.25)" fill="#545454"/>
                    </g>
                </g>
                <path d="M47.146,193.406a.583.583,0,0,0,0,.824l1.67,1.669a.582.582,0,0,0,.823,0l1.67-1.669a.583.583,0,0,0-.824-.824l-.675.675v-2.536a.583.583,0,1,0-1.165,0v2.536l-.675-.675A.583.583,0,0,0,47.146,193.406Z" transform="translate(-3.09 -3.729)" fill="#dbdbdb"/>
            </g>
        </svg>
    )
}

export default DownloadableContentSvg
