import React from 'react'
import {ProgressBar} from 'react-bootstrap';

const CarouselCard = ({points, posts}) => {
  return (
    <div className='card-container w-100 radius-20 text-center d-flex flex-column flex-md-row align-items-center justify-content-between'>
        <div className='left pink py-5 radius d-flex align-items-center justify-content-center flex-column w-100'>
            <h1 className='text-heavy text-orange'>{points}</h1>
            <h2 className='text-regular -m12'>pts</h2>
        </div>

        <div className='py-5 px-3 d-flex align-items-center flex-column right w-100'>
          <h5 className='text-semibold'>Facebook Ad Post</h5>
          <div className='d-flex flex-column flex-xl-row align-items-center w-100 justify-content-center'>
              <ProgressBar now={60} className="w-100 progress mt-2" />
              <p className='mx-2 w-100 mt-2 text-orange'>{posts} More</p>
          </div>
        </div>
    </div>
  )
}

export default CarouselCard