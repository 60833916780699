import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Heading from '../components/Heading';
import NextInvoiceCard from '../components/InvoiceComponents/NextInvoiceCard';
import PreviousInvoiceCard from '../components/InvoiceComponents/PreviousInvoiceCard';
import { DataGrid } from '@mui/x-data-grid';

const InvoiceBoard = () => {
    const rows = [
        {
            id: "1",
            invoice: "Crm",
            amount: "1600",
            due_date: "15,Oct 2021",
            pay_date: "14,Oct 2021",
            status: "Paid"
        },
        {
            id: "2",
            invoice: "Ecommerce",
            amount: "1600",
            due_date: "15,Oct 2021",
            pay_date: "14,Oct 2021",
            status: "UnPaid"
        }
    ]

    const [data, setData] = useState(rows);
    const [status, setStatus] = useState('Paid');

    const dataFilter = data.filter((item)=>{
        return item.status.toLowerCase() == status.toLowerCase()
    })

    const columns = [
        { 
            field: "invoice", 
            headerName: "Invoice", 
            minWidth:180, 
            flex:1, 
        },
        { 
            field: "amount", 
            headerName: "Amount", 
            minWidth:180, 
            flex:1, 
            renderCell: (params) => (
                <span>${params.value}</span>
            ),
        },
        {
            field: 'due_date',
            headerName: 'Due Date',
            minWidth:180,
            flex:1,
            renderCell: (params) => (
                <span className='text-medium py-3 text-success'>Due Date: {params.value}</span>
            ),
        },
        {
            field: 'pay_date',
            headerName: 'Pay Date',
            minWidth:180,
            flex:1,
            renderCell: (params) => (
                <span className='text-medium py-3 text-success'>Due Date: {params.value}</span>
            ),
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth:180,
            flex:1,
            renderCell: (params) => (
                <span className={`text-medium py-3 ${params.value.toLowerCase() === 'paid' ? 'text-success' : 'text-danger'}`}>{params.value}</span>
            ),
        },
    ];


    return (
        <div className='InvoiceBoardPage Dashboard mt-4'>
            <Container>
                <Row>
                    <Col xs={12}>
                        <Heading heading="Invoice Board" subheading="Here you can manage all your invoices" />
                    </Col>

                    <Col md={6} className="mt-3">
                        <NextInvoiceCard amount="1400" invoice="Next Invoice" date="15, Oct 2021" />
                    </Col>

                    <Col md={6} className="mt-3">
                        <PreviousInvoiceCard amount="1400" invoice="Previous Invoice" dueDate="15, Oct 2021" payDate="16, Oct 2021" />
                    </Col>

                    <Col xs={12}>
                        <div className='bg-light radius-20 w-100 mt-3 p-4'>
                            <div className='d-flex flex-column flex-md-row align-items-md-center'>
                                <h5 className='text-semibold me-5'>Invoice History</h5>
                                <div className='mt-md-0 mt-2 ms-md-5 ms-0'>
                                    <button onClick={()=>setStatus('Paid')} className='btn-paid'>Paid</button>
                                    <button onClick={()=>setStatus('UnPaid')} className='btn-unpaid'>Un Paid</button>
                                </div>
                            </div>
                            <div className='mt-3' style={{ height: 350, width: '100%' }}>
                                <DataGrid
                                    className='data-table'
                                    rows={dataFilter}
                                    columns={columns}
                                    pageSize={10}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default InvoiceBoard;
