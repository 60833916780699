import React from 'react'
import CreatePasswordsForm from '../components/LoginsComponents/CreatePasswordsForm';
import Left from '../components/LoginsComponents/Left';
import Right from '../components/LoginsComponents/Right';

const CreatePassword = () => {
    return (
        <div className="Login d-flex flex-column flex-lg-row">
            <Left/>
            <Right children={<CreatePasswordsForm/>} text="Don't have an account" link="/signup" btnText="Sign Up" />
        </div>
    )
}

export default CreatePassword;