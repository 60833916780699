import React from 'react'
import LoginVerificationForm from '../components/LoginsComponents/LoginVerificationForm';
import Left from '../components/LoginsComponents/Left';
import Right from '../components/LoginsComponents/Right';

const LoginVerification = () => {
    return (
        <div className="Login d-flex flex-column flex-lg-row">
            <Left />
            <Right children={<LoginVerificationForm />} text="Don't have an account" link="/signup" btnText="Sign Up" />
        </div>
    )
}

export default LoginVerification;