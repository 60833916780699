import React from 'react';
import { Card, Button } from 'react-bootstrap'
import { MdDownload } from "react-icons/md";

const CustomCard = ({ title, date, text, image }) => {
  return (
    <Card className='display-card radius-20 w-100'>
      <Card.Img className='w-100 card-img' variant="top" src={image} />
      <Card.Body>
        <Card.Title className="d-flex align-items-center justify-content-between">
          <h6 className='text-semibold'>{title}</h6>
          <h6 style={{fontSize:"14px"}} className='text-medium text-muted'>{date}</h6>
        </Card.Title>

        <Card.Text className='text-medium'>{text.slice(0, 80)}</Card.Text>
      </Card.Body>
      <Button className='text-center download-btn orange text-white py-3'><MdDownload className='icons' /> Download</Button>
    </Card>
  )
}

export default CustomCard;