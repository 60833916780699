import React from 'react';
import { NavLink } from 'react-router-dom';

const Setting = () => {
  return (
    <>
      <NavLink to="/profile" activeClassName="active" className="pb-4 d-block text-semibold navlink">Profile</NavLink>
      <NavLink to="/notification" activeClassName="active" className="pb-4 d-block text-semibold navlink">Notification</NavLink>
      <NavLink to="/password" activeClassName="active" className="d-block text-semibold navlink">Password</NavLink>
    </>
  )
}

export default Setting;