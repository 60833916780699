import React from 'react'

const AppBoardSvg = () => {
    return (
        <svg viewBox="0 0 29.5 29.5">
            <g id="IconHover">
                <path d="M10.727,0H2.682A2.684,2.684,0,0,0,0,2.682v8.045a2.684,2.684,0,0,0,2.682,2.682h8.045a2.684,2.684,0,0,0,2.682-2.682V2.682A2.685,2.685,0,0,0,10.727,0Z" fill="#545454"/>
            </g>
            <path d="M266.727,0h-8.045A2.684,2.684,0,0,0,256,2.682v8.045a2.684,2.684,0,0,0,2.682,2.682h8.045a2.684,2.684,0,0,0,2.682-2.682V2.682A2.685,2.685,0,0,0,266.727,0Z" transform="translate(-239.909)" fill="#ccced6"/>
            <path d="M10.727,256H2.682A2.684,2.684,0,0,0,0,258.682v8.045a2.684,2.684,0,0,0,2.682,2.682h8.045a2.684,2.684,0,0,0,2.682-2.682v-8.045A2.685,2.685,0,0,0,10.727,256Z" transform="translate(0 -239.909)" fill="#ccced6"/>
            <g id="IconHover">
                <path d="M309.345,302.549h-2.912v-2.912a.97.97,0,0,0-.971-.971H303.52a.97.97,0,0,0-.971.971v2.912h-2.912a.97.97,0,0,0-.971.971v1.942a.97.97,0,0,0,.971.971h2.912v2.912a.97.97,0,0,0,.971.971h1.942a.97.97,0,0,0,.971-.971v-2.912h2.912a.97.97,0,0,0,.971-.971V303.52A.97.97,0,0,0,309.345,302.549Z" transform="translate(-281.695 -281.695)" fill="#545454"/>
            </g>
        </svg>
    )
}

export default AppBoardSvg
