import React from 'react'

const MeetingCard = ({title, priority}) => {
    return (
        <div className='border-bottom pb-3 mb-3 d-flex justify-content-between align-items-center'>
            <h6 className='text-medium'>{title}</h6>
            <small className="pink text-medium radius-20">{priority}</small>
        </div>
    )
}

export default MeetingCard;