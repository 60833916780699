import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import TaskCard from '../components/MarketingComponents/TaskCard';
import MeetingCard from '../components/MarketingComponents/MeetingCard';
import MemberCard from '../components/MarketingComponents/MemberCard';
import Heading from '../components/Heading';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';

const rows = [
    {
        id: "1",
        project_name: "Crm",
        phase_status: "Alpha Phase",
        assignee: "Shaun Murphy",
        reporter: "Aron Glassman",
        last_update: "2 hours ago",
        priority: "High"
    },
    {
        id: "2",
        project_name: "Ecommerce",
        phase_status: "Alpha Phase",
        assignee: "Shaun Murphy",
        reporter: "Aron Glassman",
        last_update: "2 hours ago",
        priority: "High"
    }
]

const columns = [
    { 
        field: "project_name", 
        headerName: "Project Name", 
        minWidth: 150,
        flex:1, 
    },
    {
        field: 'phase_status',
        headerName: 'Phase Status',
        minWidth: 150,
        flex:1,
        renderCell: (params) => (
            <div className='d-flex align-items-center'>
                <CircularProgress variant="determinate" value="80" className='text-success circular-progress' />
                <span className=''>{params.value}</span>
            </div>
        ),
    },
    { 
        field: "assignee", 
        headerName: "Assignee", 
        minWidth: 150,
        flex:1, 
    },
    { 
        field: "reporter", 
        headerName: "Reporter", 
        minWidth: 150,
        flex:1,
    },
    { 
        field: "last_update", 
        headerName: "Last Update", 
        minWidth: 150,
        flex:1,
    },
    {
        field: 'priority',
        headerName: 'Priority',
        minWidth: 150,
        flex:1,
        renderCell: (params) => (
            <small className="pink px-3 py-1 radius-20 text-medium">{params.value}</small>
        ),
    },
];

const MarketingBoard = () => {
    return (
        <div className='MarketingBoardPage Dashboard mt-4'>
            <Container>
                <Row>
                    <Col xs={12}>
                        <Heading heading="Marketing Board" subheading="Here you can find all your tasks" />
                    </Col>

                    <Col xs={12}>
                        <div className='bg-light radius-20 w-100 mt-3 p-4'>
                            <h5 className='text-semibold'>Marketing Tasks</h5>
                            <div className='mt-3' style={{ height: 350, width: '100%' }}>
                                <DataGrid
                                    className='data-table'
                                    rows={rows}
                                    columns={columns}
                                    pageSize={10}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col xl={4} lg={6} md={6}>
                        <div className='bg-light radius-20 w-100 mt-3 p-4 h-150'>
                            <h5 className='text-semibold mb-3'>Next Deadline</h5>
                            <TaskCard title="Papp App" priority="High" update="Last Update: 2 hours ago" phase="Alpha Phase" />
                            <TaskCard title="Papp App" priority="High" update="Last Update: 2 hours ago" phase="Alpha Phase" />
                        </div>
                    </Col>

                    <Col xl={4} lg={6} md={6}>
                        <div className='bg-light radius-20 w-100 mt-3 p-4 h-150'>
                            <h5 className='text-semibold mb-3-'>Next Marketing Meeting </h5>
                            <MeetingCard title="Meeting with client" time="Today 10:00 AM" />
                            <MeetingCard title="Meeting with client" time="Today 10:00 AM" />
                            <MeetingCard title="Meeting with client" time="Today 10:00 AM" />
                        </div>
                    </Col>

                    <Col xl={4} lg={6} md={6}>
                        <div className='bg-light radius-20 w-100 mt-3 p-4 h-150'>
                            <h5 className='text-semibold mb-3'>Members</h5>
                            <MemberCard name="Ali" />
                            <MemberCard name="Ahmed" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default MarketingBoard;
