import React from 'react'

const MarketingBoardSvg = () => {
    return (
        
        <svg id="IconHover" viewBox="0 0 35.5 27.5">
            <g transform="translate(-240.25 -169.25)">
            <g>
                <rect width="4" height="11" transform="translate(248 181)" fill="#8fe2ff"/>
            </g>
            <g>
                <rect width="4" height="15" transform="translate(264 177)" fill="#8fe2ff"/>
            </g>
            <g>
                <path d="M241,196.75h34a.75.75,0,0,0,0-1.5h-1.25V173a.75.75,0,0,0-.75-.75H258.75V170a.75.75,0,0,0-1.5,0v2.25H243a.75.75,0,0,0-.75.75v22.25H241a.75.75,0,0,0,0,1.5Z" fill="#ccced6"/>
                <rect width="28.5" height="21.5" transform="translate(243.75 173.75)" fill="#ccced6"/>
            </g>
            <g id="IconHover">
                <rect width="5.5" height="16.5" rx="0.75" transform="translate(263.25 176.25)" fill="#545454"/>
                <rect width="2.5" height="13.5" transform="translate(264.75 177.75)" fill="#545454"/>
            </g>
            <g id="IconHover">
                <rect width="5.5" height="12.5" rx="0.75" transform="translate(247.25 180.25)" fill="#545454"/>
                <rect width="2.5" height="9.5" transform="translate(248.75 181.75)" fill="#545454"/>
            </g>
            <g id="IconHover">
                <rect width="5.5" height="10.5" rx="0.75" transform="translate(255.25 182.25)" fill="#545454"/>
                <rect width="2.5" height="7.5" transform="translate(256.75 183.75)" fill="#545454"/>
            </g>
            </g>
        </svg>      
    )
}

export default MarketingBoardSvg
