import React, { useState } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { EMAIL_MAX_LENGTH,EMAIL_REQUIRED,EMAIL_FORMAT} from '../../constants'
import {success,error} from '../../msg';
import { ToastContainer } from 'react-toastify';

const LoginVerificationForm = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [hide, setHide] = useState(true);
    const navigate = useNavigate();

    const onSubmit = (data) => {
        success("Verification code has been sent to your email")
        setTimeout(() => {
            navigate('/verificationcode');
        }, 1500);
    }

    return (
        <div className='forms'>
            <ToastContainer/>
            <Container>
                <Row className='justify-content-center'>
                    <Col lg={10}>
                        <div className="text-center">
                            <h1 className='text-semibold'>Verify Your Identity</h1>
                            <p className='text-medium text-muted'>We will send instructions to reset your password</p>
                        </div>

                        <Form onSubmit={handleSubmit(onSubmit)} className="mt-4">
                            <Row className="justify-content-center">
                                <Col sm={12} md={12} lg={12}>
                                    <Form.Group controlId="formEmail">
                                        <Form.Control
                                            className="input mb-2"
                                            type="email"
                                            placeholder="Enter email"
                                            {...register("email",
                                                {
                                                    maxLength: {
                                                        value: 256,
                                                        message: EMAIL_MAX_LENGTH
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: EMAIL_REQUIRED
                                                    },
                                                    pattern: {
                                                        value: /\S+@\S+\.\S+/,
                                                        message: EMAIL_FORMAT
                                                    }
                                                })
                                            }
                                        />
                                    </Form.Group>
                                    {errors.email && <small className='error'>{errors.email.message}</small>}
                                </Col>

                                <Col sm={12} md={12} lg={12}>
                                    <Button className="btn-submit w-100" type="submit">
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default LoginVerificationForm
