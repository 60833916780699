import React from 'react'
import { Outlet } from 'react-router-dom'
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import CarouselCard from '../components/OverviewComponents/CarouselCard';
import TaskCard from '../components/OverviewComponents/TaskCard';
import Heading from '../components/Heading';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import InvoiceCard from '../components/OverviewComponents/InvoiceCard';

const Home = () => {
    ChartJS.register(ArcElement, Tooltip, Legend);
    const dummyData = {
        labels: ['Total Points', 'Current Points'],
        datasets: [
            {
                label: '# of Votes',
                data: [8000, 10000],
                backgroundColor: [
                    'rgba(230, 1, 6, 1)',
                    'rgba(14, 58, 79, 1)',
                ],
                borderColor: [
                    'rgba(230, 1, 6, 1)',
                    'rgba(14, 58, 79, 1)',
                ],
                borderWidth: 1,
                borderRadius: 0,
                hoverOffset: 4
            },
        ],
    };
    return (
        <div className="HomePage Dashboard mt-4">
            <Container>
                <Row>
                    <Col xs={12}>
                        <Heading heading="Hi Steve!" subheading="Welcome back again" />
                    </Col>

                    <Col xs={12}>
                        <div style={{ height: "50px" }} className="bg-light radius-20 w-100 mt-3"></div>
                    </Col>

                    <Col xs={12}>
                        <div className='bg-light radius-20 w-100 mt-3 p-4'>
                            <h5 className='text-semibold'>Rewards and Points</h5>
                            <Row className='mt-3'>
                                <Col lg={6}>
                                    <div className='mb-3' style={{ maxWidth: "500px", maxHeight: "500px" }}>
                                        <Doughnut data={dummyData} />
                                    </div>
                                </Col>

                                <Col lg={6} className="my-auto">
                                    <Carousel>
                                        <Carousel.Item className='carousel-item'>
                                            <CarouselCard points="6,250" posts="5450" />
                                        </Carousel.Item>
                                        <Carousel.Item className='carousel-item'>
                                            <CarouselCard points="6,250" posts="5450" />
                                        </Carousel.Item>
                                        <Carousel.Item className='carousel-item'>
                                            <CarouselCard points="6,250" posts="5450" />
                                        </Carousel.Item>
                                    </Carousel>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col lg={4} md={6}>
                        <div className="bg-light radius-20 mt-3 p-4">
                            <h5 className='text-semibold mb-3'>Marketing Task</h5>
                            <TaskCard title="Papp App" priority="High" update="Last Update: 2 hours ago" phase="Alpha Phase" />
                        </div>
                    </Col>

                    <Col lg={4} md={6}>
                        <div className="bg-light radius-20 mt-3 p-4">
                            <h5 className='text-semibold mb-3'>App Task</h5>
                            <TaskCard title="Papp App" priority="High" update="Last Update: 2 hours ago" phase="Alpha Phase" />
                        </div>
                    </Col>

                    <Col lg={4} md={6}>
                        <InvoiceCard amount="1400" invoice="Next Invoice" date="15, Oct 2021" />
                    </Col>
                </Row>
            </Container>
            <Outlet />
        </div>
    )
}

export default Home;

