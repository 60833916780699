import React from 'react';
import { Container, Row, Col, ProgressBar } from 'react-bootstrap';
import TaskCard from '../components/AppBoardComponents/TaskCard';
import MeetingCard from '../components/AppBoardComponents/MeetingCard';
import Heading from '../components/AppBoardComponents/Heading';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';

const AppBoard = () => {
    const rows = [
        {
            id: "1",
            project_name: "Crm",
            current_phase: "Alpha Phase",
            progress: "60",
            priority: "High",
        },
        {
            id: "2",
            project_name: "Ecomerce",
            current_phase: "Alpha Phase",
            progress: "60",
            priority: "High",
        }
    ]

    const columns = [
        { 
            field: "project_name", 
            headerName: "Project Name", 
            minWidth:150,
            flex:1, 
        },
        {
            field: 'current_phase',
            headerName: 'Current Phase',
            minWidth:150,
            flex:1,
            renderCell: (params) => (
                <div className='d-flex align-items-center'>
                    <CircularProgress variant="determinate" value="75" className='text-success circular-progress' />
                    <span className=''>{params.value}</span>
                </div>
            ),
        },
        {
            field: 'progress',
            headerName: 'Progress',
            minWidth:150,
            flex:1,
            renderCell: (params) => (
                <div className='d-flex align-items-center w-100'>
                    <ProgressBar now={60} className="w-100 progress" />
                    <span className='px-2'>{params.value}%</span>
                </div>
            ),
        },
        {
            field: 'priority',
            headerName: 'Priority',
            minWidth:150,
            flex:1,
            renderCell: (params) => (
                <small className="pink px-3 py-1 radius-20 text-medium">{params.value}</small>
            ),
        }
    ];

    return (
        <div className='AppBoardPage Dashboard mt-4'>
            <Container>
                <Row>
                    <Col xs={12}>
                        <Heading heading="App Board" subheading="Here you can manage all your app tasks" />
                    </Col>

                    <Col xs={12}>
                        <div className='bg-light radius-20 w-100 mt-3 p-4'>
                            <h5 className='text-semibold'>Current Tasks</h5>
                            <div className='mt-3' style={{ height: 350, width: '100%' }}>
                                <DataGrid
                                    className='data-table'
                                    rows={rows}
                                    columns={columns}
                                    pageSize={10}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12} md={6}>
                        <div className='bg-light radius-20 w-100 mt-3 p-4 h-150'>
                            <h5 className='text-semibold mb-3'>Marketing Tasks</h5>
                            <TaskCard title="Papp App" priority="High" update="Last Update: 2 hours ago" phase="Alpha Phase" />
                            <TaskCard title="Papp App" priority="High" update="Last Update: 2 hours ago" phase="Alpha Phase" />
                        </div>
                    </Col>

                    <Col sm={12} md={6}>
                        <div className='bg-light radius-20 w-100 mt-3 p-4 h-150'>
                            <h5 className='text-semibold mb-3'>Next Meeting</h5>
                            <MeetingCard title="Meeting with client" priority="High" />
                            <MeetingCard title="Meeting with client" priority="High" />
                            <MeetingCard title="Meeting with client" priority="High" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AppBoard;
