import React, { useState } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom'
import {CODE_MIN_LENGTH, CODE_MAX_LENGTH, CODE_REQUIRED} from '../../constants'
import {success,error} from '../../msg';
import { ToastContainer } from 'react-toastify';

const VerificationCodeForm = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();

    const onSubmit = (data) => {
        success("Verification successfull")
        setTimeout(() => {
            navigate('/createpassword');
        }, 1500);
    }

    return (
        <div className='forms'>
            <ToastContainer/>
            <Container>
                <Row className='justify-content-center'>
                    <Col lg={10}>
                        <div className="text-center">
                            <h1 className='text-semibold'>Verification Code</h1>
                            <p className='text-medium text-muted'>We have just send a code to yourname@mail.com</p>
                        </div>

                        <Form onSubmit={handleSubmit(onSubmit)} className="mt-4">
                            <Row className="justify-content-center">
                                <Col sm={12} md={12} lg={12}>
                                    <Form.Group controlId="code">
                                        <Form.Control
                                            className="input mb-2"
                                            type="number"
                                            name="code"
                                            placeholder="Enter 6 digits code"
                                            {...register("code",
                                                {
                                                    minLength: {
                                                        value: 6,
                                                        message: CODE_MIN_LENGTH
                                                    },
                                                    maxLength: {
                                                        value: 6,
                                                        message: CODE_MAX_LENGTH
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: CODE_REQUIRED
                                                    },
                                                })
                                            }
                                        />
                                    </Form.Group>
                                    {errors.code && <small className='error'>{errors.code.message}</small>}
                                </Col>

                                <Col sm={12} md={12} lg={12}>
                                    <Button className="btn-submit w-100" type="submit">
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default VerificationCodeForm
