import React from 'react'
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const Input = styled('input')({
    display: 'none',
});


const UploadButton = () => {
    return (
        <label htmlFor="contained-button-file">
            <Input accept="image/*" id="contained-button-file" multiple type="file" />
            <Button className='btns btn-upload text-white text-medium px-3' variant="contained" component="span">
                Upload
            </Button>
        </label>
    )
}

export default UploadButton