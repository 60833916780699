import React from 'react'

const SettingsSvg = () => {
    return (
        <svg viewBox="0 0 25.761 25.761">
            <g transform="translate(-40.5 -2.808)">
                <g transform="translate(40.5 2.808)">
                    <path d="M65.566,43.434l-1.745-.439a10.723,10.723,0,0,0-1-2.41l.922-1.547a.919.919,0,0,0-.139-1.124l-2.228-2.227a.918.918,0,0,0-1.127-.145l-1.547.924a10.794,10.794,0,0,0-2.409-1l-.442-1.751a.922.922,0,0,0-.891-.689H51.8a.919.919,0,0,0-.893.7l-.441,1.745a10.775,10.775,0,0,0-2.408,1L46.613,35.6a.941.941,0,0,0-1.223.08l-2.233,2.232a.919.919,0,0,0-.14,1.122l.924,1.549a10.782,10.782,0,0,0-1,2.41l-1.741.438a.919.919,0,0,0-.7.894v3.157a.918.918,0,0,0,.7.893l1.745.439a10.794,10.794,0,0,0,1,2.41l-.923,1.546a.917.917,0,0,0,.139,1.124l2.228,2.228a.918.918,0,0,0,1.127.144l1.548-.923a10.725,10.725,0,0,0,2.409,1l.439,1.742a.919.919,0,0,0,.894.7h3.157a.916.916,0,0,0,.892-.694l.44-1.746a10.74,10.74,0,0,0,2.41-1l1.553.927a.922.922,0,0,0,1.116-.144L63.6,53.9a.916.916,0,0,0,.145-1.126l-.923-1.548a10.751,10.751,0,0,0,1-2.41l1.742-.438a.919.919,0,0,0,.7-.894V44.327A.919.919,0,0,0,65.566,43.434ZM53.38,51.714a5.809,5.809,0,1,1,5.809-5.809A5.815,5.815,0,0,1,53.38,51.714Z" transform="translate(-40.5 -33.025)" fill="#ccced6"/>
                    <g id="IconHover">
                        <circle cx="4.293" cy="4.293" r="4.293" transform="translate(8.587 8.587)" fill="#545454"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default SettingsSvg
