import React,{useState, createContext} from "react";
import Navigation from './Navigation';
import './App.css';

// const AppRoute = ({component:Component, layout:Layout, ...rest}) => {
//    return <Route {...rest} render={props => {
//     <Layout><Component {...props}></Component></Layout>
//   }}></Route>;
// }

const Toggle = createContext();

const App = ()=>{
  const [toggle, setToggle] = useState(true);
  
  return(
    <Toggle.Provider value={[toggle, setToggle]}>
      <Navigation/>
    </Toggle.Provider>
  )
}

export default App;
export{Toggle}
