
// Text Input
export const MIN_LENGTH = "Min length is 5"
export const MAX_LENGTH = "Max length is 50";
export const PROJECT_REQUIRED = "Project name is required";
export const LOCATION_REQUIRED = "City/Country is required";
export const FIRST_NAME_REQUIRED = "First name is required";
export const LAST_NAME_REQUIRED = "Last name is required";


// Email Input
export const EMAIL_MAX_LENGTH = "Max length is 256";
export const EMAIL_REQUIRED = "Email is required";
export const EMAIL_FORMAT = "Invalid email format";

// Password Input
export const PASSWORD_MIN_LENGTH = "Min length is 8";
export const PASSWORD_MAX_LENGTH = "Max length is 30";
export const PASSWORD_REQUIRED = "Password is required";
export const NEW_PASSWORD_REQUIRED = "New password is required";
export const CONFIRM_PASSWORD_REQUIRED = "Confirm password is required";
export const OLD_PASSWORD_REQUIRED = "Old password is required";


// Url Input
export const LINKEDIN_REQUIRED = "Linkedin profile link is required";
export const FACEBOOK_REQUIRED = "Facebook profile link is required";


// Number Input
export const CODE_MIN_LENGTH = "Min length is 6";
export const CODE_MAX_LENGTH = "Max length is 6";
export const CODE_REQUIRED = "Verification code is required";


// Phone Input
export const PHONE_LENGTH = "Length is 11";
export const PHONE_REQUIRED = "Phone number is required";


// Date Input
export const DOB_REQUIRED = "Date of birth is required";
