import React from 'react';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import DownloadableCustomCard from '../components/DownloadableComponents/DownloadableCustomCard';
import Heading from '../components/Heading';

const DownloadableContent = () => {
    const data = [
        {
            title: "Title Here",
            date: "20 Oct,2021",
            paragraph: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr sed",
            image:"https://images.pexels.com/photos/1563356/pexels-photo-1563356.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
        },

        {
            title: "Title Here",
            date: "20 Oct,2021",
            paragraph: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr sed",
            image:"https://images.pexels.com/photos/1563356/pexels-photo-1563356.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
        },

        {
            title: "Title Here",
            date: "20 Oct,2021",
            paragraph: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr sed",
            image:"https://images.pexels.com/photos/1563356/pexels-photo-1563356.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
        },

        {
            title: "Title Here",
            date: "20 Oct,2021",
            paragraph: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr sed",
            image:"https://images.pexels.com/photos/1563356/pexels-photo-1563356.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
        },
        {
            title: "Title Here",
            date: "20 Oct,2021",
            paragraph: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr sed",
            image:"https://images.pexels.com/photos/1563356/pexels-photo-1563356.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
        },

        {
            title: "Title Here",
            date: "20 Oct,2021",
            paragraph: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr sed",
            image:"https://images.pexels.com/photos/1563356/pexels-photo-1563356.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
        },

        {
            title: "Title Here",
            date: "20 Oct,2021",
            paragraph: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr sed",
            image:"https://images.pexels.com/photos/1563356/pexels-photo-1563356.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
        },

        {
            title: "Title Here",
            date: "20 Oct,2021",
            paragraph: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr sed",
            image:"https://images.pexels.com/photos/1563356/pexels-photo-1563356.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
        },
    ]
    return (
        <div className='DownloadableContentPage Dashboard mt-4'>
            <Container>
                <Row>
                    <Col xs={12}>
                        <Heading heading="Downloadable Content!" subheading="Here you can manage your profile, notifications and password." />
                    </Col>

                    <Col xs={12}>
                        <div className='bg-light radius-20 w-100 mt-3 p-4'>
                            <Tabs defaultActiveKey="Marketing-Collaterals" id="Marketing-Collaterals" className="w-100 mb-3">
                                <Tab eventKey="Marketing-Collaterals" title="Marketing Collaterals" className='mt-4 w-100 '>
                                    <Row>
                                        {
                                            data.map((v, i) => {
                                                return (
                                                    <Col xxl={3} xl={4} lg={4} lg={6} className="mb-3">
                                                        <DownloadableCustomCard title={v.title} date={v.date} text={v.paragraph} image={v.image} />

                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>
                                </Tab>
                                <Tab eventKey="Brand-Assets" title="Brand Assets" className='mt-4 w-100'>
                                    <Row>
                                        {
                                            data.map((v, i) => {
                                                return (
                                                    <Col xxl={3} xl={4} lg={4} lg={6} className="mb-3">
                                                        <DownloadableCustomCard title={v.title} date={v.date} text={v.paragraph} image={v.image} />

                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>
                                </Tab>
                                <Tab eventKey="Blogs" title="Blogs" className='mt-4 w-100'>
                                    <Row>
                                        {
                                            data.map((v, i) => {
                                                return (
                                                    <Col xxl={3} xl={4} lg={4} lg={6} className="mb-3">
                                                        <DownloadableCustomCard title={v.title} date={v.date} text={v.paragraph} image={v.image} />
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>
                                </Tab>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default DownloadableContent;
