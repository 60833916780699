import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Heading from '../components/Heading';
import Setting from '../components/Setting';
import NotificationForm from '../components/SettingComponents/NotificationForm';

const Notification = () => {
    return (
        <div className='SettingsPage Dashboard mt-4'>
            <Container>
                <Row>
                    <Col xs={12}>
                        <Heading heading="Settings" subheading="Here you can manage all your profile" />
                    </Col>

                    <Col xs={12} md={4} lg={3}>
                        <div className='bg-light radius-20 w-100 mt-3 p-4'>
                            <Setting />
                        </div>
                    </Col>

                    <Col xs={12} md={8} lg={9}>
                        <div className='bg-light radius-20 w-100 mt-3 p-4'>
                            <NotificationForm/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Notification;
