import React from 'react';
import {Form,Button} from 'react-bootstrap'

const NotificationForm = () => {
    return (
        <form>
            <Form>
                <div className='d-flex align-items-center justify-content-between mb-2'>
                    <p>Notify me when someone comment</p>
                    <Form.Check type="switch" id="custom-switch" />
                </div>

                <div className='d-flex align-items-center justify-content-between mb-2'>
                    <p>Notify me when someone add</p>
                    <Form.Check type="switch" id="custom-switch" />
                </div>

                <div className='d-flex align-items-center justify-content-between mb-2'>
                    <p>Notify me when someone add</p>
                    <Form.Check type="switch" id="custom-switch" />
                </div>

                <div className='d-flex justify-content-end mt-5'>
                    <Button className="orange btns btn-save" type="submit">Save</Button>
                </div>
            </Form>
        </form>
    )
}

export default NotificationForm;