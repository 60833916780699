import React, { useState } from 'react'
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { useNavigate, NavLink } from 'react-router-dom';
import {PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH, NEW_PASSWORD_REQUIRED, CONFIRM_PASSWORD_REQUIRED} from '../../constants'
import {success,error} from '../../msg';
import { ToastContainer } from 'react-toastify';

const CreatePasswordsForm = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [newPassword, setNewPassword] = useState(true);
    const [newConfirmPassword, setNewConfirmPassword] = useState(true);
    const password = watch("newPassword")
    const navigate = useNavigate();

    const onSubmit = (data) => {
        success("Password has been changed")
        setTimeout(() => {
            navigate('/');
        }, 1500);
    }

    return (
        <div className='forms'>
            <Container>
                <ToastContainer/>
                <Row className='justify-content-center'>
                    <Col lg={10}>
                        <div className="text-center">
                            <h1 className='text-semibold'>Create New Password</h1>
                            <p className='text-medium text-muted'>Now create a strong password for better security</p>
                        </div>

                        <Form onSubmit={handleSubmit(onSubmit)} className="mt-4">
                            <Row className="justify-content-center">
                                <Col sm={7}>
                                    <InputGroup>
                                        <Form.Control
                                            className='input mb-2 password-field'
                                            type={newPassword ? "password" : "text"}
                                            name="newPassword"
                                            placeholder="Enter new password"
                                            {...register("newPassword",
                                                {
                                                    minLength: {
                                                        value: 8,
                                                        message: PASSWORD_MIN_LENGTH
                                                    },
                                                    maxLength: {
                                                        value: 30,
                                                        message: PASSWORD_MAX_LENGTH
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: NEW_PASSWORD_REQUIRED
                                                    },
                                                })
                                            }
                                        />
                                        <InputGroup.Text>
                                            {
                                                newPassword ? <BsEyeFill onClick={() => setNewPassword(false)} className='eye-icon' /> : <BsEyeSlashFill onClick={() => setNewPassword(true)} className='eye-icon' />
                                            }
                                        </InputGroup.Text>
                                    </InputGroup>
                                    {errors.newPassword && <small className='error'>{errors.newPassword.message}</small>}
                                </Col>

                                <Col sm={7}>
                                    <InputGroup>
                                        <Form.Control
                                            className='input mb-2 password-field'
                                            type={newConfirmPassword ? "password" : "text"}
                                            name="confirmPassword"
                                            placeholder="Enter confirm password"
                                            {...register("confirmPassword",
                                                {
                                                    minLength: {
                                                        value: 8,
                                                        message: PASSWORD_MIN_LENGTH
                                                    },
                                                    maxLength: {
                                                        value: 30,
                                                        message: PASSWORD_MAX_LENGTH
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: CONFIRM_PASSWORD_REQUIRED
                                                    },
                                                    validate: (value)=>
                                                        value===password || "The Passwords do not match"
                                                })
                                            }
                                        />
                                        <InputGroup.Text>
                                            {
                                                newConfirmPassword ? <BsEyeFill onClick={() => setNewConfirmPassword(false)} className='eye-icon' /> : <BsEyeSlashFill onClick={() => setNewConfirmPassword(true)} className='eye-icon' />
                                            }
                                        </InputGroup.Text>
                                    </InputGroup>
                                    {errors.confirmPassword && <small className='error'>{errors.confirmPassword.message}</small>}
                                </Col>

                                <Col sm={7} md={7} lg={7}>
                                    <Button className="btn-submit mt-4 w-100" type="submit">
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default CreatePasswordsForm
