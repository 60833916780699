import React from 'react'
import SignUpForm from '../components/LoginsComponents/SignUpForm';
import Left from '../components/LoginsComponents/Left';
import Right from '../components/LoginsComponents/Right';

const SignUp = () => {
    return (
        <div className="Login d-flex flex-column flex-lg-row">
            <Left />
            <Right children={<SignUpForm />} text="Already have an account" link="/signin" btnText="Sign In" />
        </div>
    )
}

export default SignUp;