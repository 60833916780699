import React from 'react'

const Heading = ({ heading, subheading }) => {
  return (
    <div>
      <h1 className='text-bold'>{heading}</h1>
      <p className='text-medium -m8'>{subheading}</p>
    </div>
  )
}

export default Heading