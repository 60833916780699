import React, { useState } from 'react'
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { useNavigate } from "react-router-dom";
import {success,error} from '../../msg';
import { ToastContainer } from 'react-toastify';

import {MIN_LENGTH,MAX_LENGTH,PROJECT_REQUIRED,LOCATION_REQUIRED,EMAIL_MAX_LENGTH,EMAIL_REQUIRED,EMAIL_FORMAT,
    PASSWORD_MIN_LENGTH,PASSWORD_MAX_LENGTH,PASSWORD_REQUIRED,LINKEDIN_REQUIRED,FACEBOOK_REQUIRED,PHONE_LENGTH,
    PHONE_REQUIRED,DOB_REQUIRED
} from '../../constants'


const SignUpForm = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [hide, setHide] = useState(true);
    const navigate = useNavigate();

    const onSubmit = (data) => {
        // e.preventDefault()
        success("Registration Successfull")
        setTimeout(() => {
            navigate('/signin');
        }, 1500);
    }

    return (
        <div className='forms'>
            <ToastContainer/>
            <Container>
                <Row className='justify-content-center'>
                    <Col lg={10}>
                        <div className='text-center'>
                            <h1 className='text-semibold'>Sign Up</h1>
                            <p className='text-medium text-muted'>Let's get you all set up so you can verify your personal account <br className="d-none d-md-block" /> and begin setting up your profile</p>
                        </div>
                        <Form onSubmit={handleSubmit(onSubmit)} className="mt-4" action="" method="POST">
                            <Row className="justify-content-center">
                                <Col sm={6} md={6} lg={6}>
                                    <Form.Group controlId="formEmail">
                                        <Form.Control
                                            className="input mb-2"
                                            type="email"
                                            name="email"
                                            placeholder="Enter email"
                                            {...register("email",
                                                {
                                                    maxLength: {
                                                        value: 256,
                                                        message: EMAIL_MAX_LENGTH
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: EMAIL_REQUIRED
                                                    },
                                                    pattern: {
                                                        value: /\S+@\S+\.\S+/,
                                                        message: EMAIL_FORMAT
                                                    }
                                                })
                                            }
                                        />
                                        {errors.email && <small className='error'>{errors.email.message}</small>}
                                    </Form.Group>
                                </Col>
                                <Col sm={6} md={6} lg={6}>
                                    <InputGroup>
                                        <Form.Control
                                            className='input mb-2 password-field'
                                            type={hide ? "password" : "text"}
                                            placeholder="Password"
                                            name="password"
                                            {...register("password",
                                                {
                                                    minLength: {
                                                        value: 8,
                                                        message: PASSWORD_MIN_LENGTH
                                                    },
                                                    maxLength: {
                                                        value: 30,
                                                        message: PASSWORD_MAX_LENGTH
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: PASSWORD_REQUIRED
                                                    },
                                                })
                                            }
                                        />
                                        <InputGroup.Text>
                                            {
                                                hide ? <BsEyeFill onClick={() => setHide(false)} className='eye-icon' /> : <BsEyeSlashFill onClick={() => setHide(true)} className='eye-icon' />
                                            }
                                        </InputGroup.Text>
                                    </InputGroup>
                                    {errors.password && <small className='error'>{errors.password.message}</small>}
                                </Col>
                                <Col sm={6} md={6} lg={6}>
                                    <Form.Group controlId="formPhone">
                                        <Form.Control
                                            className="input mb-2"
                                            type="tel"
                                            name="phone"
                                            placeholder="Phone No"
                                            {...register("phone",
                                                {
                                                    minLength: {
                                                        value: 11,
                                                        message: PHONE_LENGTH
                                                    },
                                                    maxLength: {
                                                        value: 11,
                                                        message: PHONE_LENGTH
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: PHONE_REQUIRED
                                                    },
                                                })
                                            }
                                        />
                                    </Form.Group>
                                    {errors.phone && <small className='error'>{errors.phone.message}</small>}
                                </Col>
                                <Col sm={6} md={6} lg={6}>
                                    <Form.Group controlId="dob">
                                        <Form.Control
                                            className="input mb-2"
                                            type="date"
                                            name="dob"
                                            placeholder="Date of Birth"
                                            {...register("dob",
                                                {
                                                    required: {
                                                        value: true,
                                                        message: DOB_REQUIRED
                                                    },
                                                })
                                            }
                                        />
                                    </Form.Group>
                                    {errors.dob && <small className='error'>{errors.dob.message}</small>}
                                </Col>
                                <Col sm={6} md={6} lg={6}>
                                    <Form.Group controlId="formProject">
                                        <Form.Control
                                            className="input mb-2"
                                            type="text"
                                            name="project"
                                            placeholder="Project Name"
                                            {...register("project",
                                                {
                                                    minLength: {
                                                        value: 5,
                                                        message: MIN_LENGTH
                                                    },
                                                    maxLength: {
                                                        value: 50,
                                                        message: MAX_LENGTH
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: PROJECT_REQUIRED
                                                    },
                                                })
                                            }
                                        />
                                    </Form.Group>
                                    {errors.project && <small className='error'>{errors.project.message}</small>}
                                </Col>
                                <Col sm={6} md={6} lg={6}>
                                    <Form.Group controlId="formLocation">
                                        <Form.Control
                                            className="input mb-2"
                                            type="text"
                                            name="location"
                                            placeholder="City/Country"
                                            {...register("location",
                                                {
                                                    minLength: {
                                                        value: 5,
                                                        message: MIN_LENGTH
                                                    },
                                                    maxLength: {
                                                        value: 256,
                                                        message: MAX_LENGTH
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: LOCATION_REQUIRED
                                                    },
                                                })
                                            }
                                        />
                                    </Form.Group>
                                    {errors.location && <small className='error'>{errors.location.message}</small>}
                                </Col>
                                <Col sm={6} md={6} lg={6}>
                                    <Form.Group controlId="formFacebookLink">
                                        <Form.Control
                                            className="input mb-2"
                                            type="url"
                                            name="facebookLink"
                                            placeholder="Facebook Profile Link"
                                            {...register("facebookLink",
                                                {
                                                    required: {
                                                        value: true,
                                                        message: FACEBOOK_REQUIRED
                                                    },
                                                })
                                            }
                                        />
                                    </Form.Group>
                                    {errors.facebookLink && <small className='error'>{errors.facebookLink.message}</small>}
                                </Col>
                                <Col sm={6} md={6} lg={6}>
                                    <Form.Group controlId="formLinkedinLink">
                                        <Form.Control
                                            className="input mb-2"
                                            type="url"
                                            name="linkedinLink"
                                            placeholder="Linkedin Profile Link"
                                            {...register("linkedinLink",
                                                {
                                                    required: {
                                                        value: true,
                                                        message: LINKEDIN_REQUIRED
                                                    },
                                                })
                                            }
                                        />
                                    </Form.Group>
                                    {errors.linkedinLink && <small className='error'>{errors.linkedinLink.message}</small>}
                                </Col>
                                <Col sm={6} md={6} lg={6}>
                                    <Button className="btn-submit mt-4 w-100" type="submit">
                                        Sign Up
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SignUpForm
