import React from 'react'

const NextInvoiceCard = ({amount, invoice, date}) => {
    return (
        <div className="text-center bg-light radius-20 h-card-185 p-4">
            <h1 className='text-semibold text-orange'>${amount}</h1>
            <h5 className='text-semibold mb-1'>{invoice}</h5>
            <p className='text-medium mb-2 text-success'>Due Date: {date}</p>
        </div>
    )
}

export default NextInvoiceCard