import React from 'react'
import { NavLink } from 'react-router-dom';

const Right = ({children, text, link, btnText}) => {
    return (
        <div className='right d-flex justify-content-center align-items-lg-center'>
            <div className='position-div'>
                <div className="bottom d-flex justify-content-between mt-3">
                    <p className='text-medium me-3'>© 2021 CRM, All Rights Reserved.</p>
                    <p className='text-medium ms-3'>{text} <NavLink className='d-inline text-orange' to={link}> {btnText}</NavLink></p>
                </div>
            </div>
            {children}
        </div>
    )
}

export default Right