import React from 'react'
import SignInForm from '../components/LoginsComponents/SignInForm';
import Left from '../components/LoginsComponents/Left';
import Right from '../components/LoginsComponents/Right';

const SignIn = () => {
    return (
        <div className="Login d-flex flex-column flex-lg-row">
            <Left />
            <Right children={<SignInForm />} text="Don't have an account" link="/signup" btnText="Sign Up" />
        </div>
    )
}

export default SignIn;