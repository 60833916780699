import React from 'react'

const PreviousInvoiceCard = ({amount, invoice, dueDate, payDate}) => {
    return (
        <div className='text-center bg-light radius-20 h-card-185 p-4'>
            <h1 className='text-semibold text-orange'>${amount}</h1>
            <h5 className='text-semibold mb-1'>{invoice}</h5>
            <div className='d-flex justify-content-center mt-2 column'>
                <p className='text-medium text-danger px-2'>Due Date: {dueDate}</p>
                <p className='text-medium text-success px-2'>Pay Date: {payDate}</p>
            </div>
        </div>
    )
}

export default PreviousInvoiceCard;