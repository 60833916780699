import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import MarketingBoard from "./pages/MarketingBoard";
import AppBoard from "./pages/AppBoard";
import InvoiceBoard from "./pages/InvoiceBoard";
import Profile from "./pages/Profile";
import DownloadableContent from "./pages/DownloadableContent";
import RewardsAndPoints from "./pages/RewardsAndPoints";
import Notification from "./pages/Notification";
import Password from "./pages/Password";
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import SideNav from "./components/SideNav";
import LoginVerification from "./pages/LoginVerification";
import VarificationCode from './pages/VarificationCode';
import CreatePassword from "./pages/CreatePassword";
import DashboardLayout from './layouts/DashboardLayout';
import ErrorPage from './pages/ErrorPage';

const Navigation = () => {
  return (
    <Router>
        <Routes>
            <Route exact path="/" element={<DashboardLayout children={<Home/>} />}></Route>
            <Route exact path="/marketingboard" element={<DashboardLayout children={<MarketingBoard/>} />}></Route>
            <Route exact path="/appboard" element={<DashboardLayout children={<AppBoard/>} />}></Route>
            <Route exact path="/invoiceboard" element={<DashboardLayout children={<InvoiceBoard/>} />}></Route>
            <Route exact path="/profile" element={<DashboardLayout children={<Profile/>} />}></Route>
            <Route exact path="/notification" element={<DashboardLayout children={<Notification/>} />}></Route>
            <Route exact path="/password" element={<DashboardLayout children={<Password/>} />}></Route>
            <Route exact path="/downloadablecontent" element={<DashboardLayout children={<DownloadableContent/>} />}></Route>
            <Route exact path="/rewardsandpoints" element={<DashboardLayout children={<RewardsAndPoints/>} />}></Route>
            <Route exact path="/profile" element={<DashboardLayout children={<Profile/>} />}></Route>
        </Routes>
          <Routes>
            {/* <Route exact path="/" element={<Home/>}/>  */}
            <Route exact path="/signin" element={<SignIn />}/>
            <Route exact path="/signup" element={<SignUp />}/>
            <Route exact path="/loginverification" element={<LoginVerification />}/>
            <Route exact path="/verificationcode" element={<VarificationCode />}/>  
            <Route exact path="/createpassword" element={<CreatePassword />}/>  
            {/* <Route path='*' exact={true}  element={<ErrorPage errorType="404" errorTitle="Something went wrong" />} /> */}
          </Routes>
    </Router>
  )
}

export default Navigation;