import React from 'react'

const ActivityCard = ({points, title}) => {
    return (
        <div className='box bg-light d-flex flex-column align-items-center flex-md-row radius-20 mt-3'>
            <div className='left p-5 pink'>
                <h5 className='text-orange text-heavy'>{points} pts</h5>
            </div>

            <div className='p-5'>
                <h5 className='text-semibold'>{title}</h5>
            </div>
        </div>
    )
}

export default ActivityCard