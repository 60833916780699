import React from 'react'
import { Col, Spinner } from 'react-bootstrap';

const TaskCard = ({ title, priority, update, phase }) => {
    return (
        <div className="">
            <div className='d-flex justify-content-between'>
                <h6 className='text-medium'>{title}</h6>
                <small className="pink text-medium radius-20">{priority}</small>
            </div>
            <p className='text-medium text-muted -m5'>{update}</p>

            <div className='d-flex align-items-center'>
                <Spinner size="sm" className='text-success' animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                <h6 className='text-medium mx-2 mt-1'>{phase}</h6>
            </div>
        </div>
    )
}

export default TaskCard