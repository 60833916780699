import React,{useContext} from 'react';
import { Link } from "react-router-dom";
import {Dropdown} from 'react-bootstrap';
import profile_icons from "../assets/images/profile.jpg";
import { GiHamburgerMenu } from 'react-icons/gi';
import { Toggle } from '../App';
import {Container, Col, Row} from 'react-bootstrap';

const Header = () => {
    const [toggle, setToggle] = useContext(Toggle);
    return (
        <header>
            <Container>
                <Row>
                    <Col xs={12} md={6} className="order-2 order-md-1">
                        <div className="SearchField mt-4 mt-md-2">
                            <form>
                                <input type="text" placeholder="Search for anything" name="search"/>
                            </form>
                        </div>
                    </Col>

                    <Col xs={12} md={6} className="order-1 order-md-2">
                        <div className="HeaderRight d-flex justify-content-end">
                            <ul>
                                <li></li>
                                <li><GiHamburgerMenu onClick={()=>setToggle(!toggle)} className='me-4 icon'/></li>
                                <li>
                                    <Dropdown>
                                        <Dropdown.Toggle className="headerButton notificationBtn notificationAlert">
                                            <svg width="100%" viewBox="0 0 26.928 31.217">
                                                <g clip-path="url(#clip-path)">
                                                    <path d="M22.72,24.027V21.109a9.445,9.445,0,0,0-6.573-9.044V10.744a2.689,2.689,0,1,0-5.378,0v1.321A9.445,9.445,0,0,0,4.2,21.109v2.918A14.518,14.518,0,0,1,.264,34.011.928.928,0,0,0,.086,35a.9.9,0,0,0,.825.555H9.067a4.465,4.465,0,0,0,8.783,0h8.156A.9.9,0,0,0,26.831,35a.928.928,0,0,0-.178-.992A14.518,14.518,0,0,1,22.72,24.027ZM12.562,10.744a.9.9,0,1,1,1.793,0V11.7c-.295-.029-.594-.045-.9-.045s-.6.015-.9.045Zm.9,26.644a2.7,2.7,0,0,1-2.535-1.829h5.069A2.7,2.7,0,0,1,13.459,37.388Z" transform="translate(0.005 -8)" fill="#545454"/>
                                                    <path className='onAlert' d="M19.2,20.242a.9.9,0,1,0,1.793,0,13.768,13.768,0,0,0-3.938-9.7.884.884,0,0,0-1.268,0,.928.928,0,0,0,0,1.293A11.931,11.931,0,0,1,19.2,20.242Z" transform="translate(5.914 -7.134)"/>
                                                    <path className='onAlert' d="M.911,21.157a.906.906,0,0,0,.9-.914A11.931,11.931,0,0,1,5.22,11.836a.928.928,0,0,0,0-1.293.884.884,0,0,0-1.268,0,13.768,13.768,0,0,0-3.938,9.7A.905.905,0,0,0,.911,21.157Z" transform="translate(0.005 -7.134)"/>
                                                </g>
                                            </svg>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="headerDropdown notificationDropdown">
                                            <span className='notificationHeading'>Notifications</span>
                                            <ul>
                                                <li>
                                                    <Link to="/">
                                                        <div className='notificationItem'>
                                                            <div className="imgProfile">
                                                                <img src={profile_icons} className="profile_icons" alt="profile_icons"/>
                                                            </div>
                                                            <div className='notifcationText'>
                                                                <span>You've added new task</span>
                                                                <span className='notificationTime'>23 mins ago</span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/">
                                                        <div className='notificationItem'>
                                                            <div className="imgProfile">
                                                                <img src={profile_icons} className="profile_icons" alt="profile_icons"/>
                                                            </div>
                                                            <div className='notifcationText'>
                                                                <span>You've added new task</span>
                                                                <span className='notificationTime'>23 mins ago</span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/">
                                                        <div className='notificationItem'>
                                                            <div className="imgProfile">
                                                                <img src={profile_icons} className="profile_icons" alt="profile_icons"/>
                                                            </div>
                                                            <div className='notifcationText'>
                                                                <span>You've added new task</span>
                                                                <span className='notificationTime'>23 mins ago</span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                                <li>
                                    <Dropdown>
                                        <Dropdown.Toggle className="headerButton profileBtn">
                                            <div className="imgProfile">
                                                <img src={profile_icons} className="profile_icons" alt="profile_icons"/>
                                            </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="headerDropdown profileDropdown">
                                            <ul>
                                                <li>
                                                    <Link to="/">My Profile</Link>
                                                </li>
                                                <li>
                                                    <Link to="/">Settings</Link>
                                                </li>
                                                <li>
                                                    <Link to="/">Log Out</Link>
                                                </li>
                                            </ul>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                            </ul>    
                        </div>
                    </Col>
                </Row>
            </Container>
        </header>
    )
}

export default Header;

// <header>
//             <div className="SearchField">
//                 <form>
//                     <input type="text" placeholder="Search for anything" name="search"/>
//                 </form>
//             </div>
//             <div className="HeaderRight">
//                 <ul>
//                     <li><GiHamburgerMenu onClick={()=>setToggle(!toggle)} className='me-4 icon'/></li>
//                     <li>
//                         <Dropdown>
//                             <Dropdown.Toggle className="headerButton notificationBtn notificationAlert">
//                                 <svg width="100%" viewBox="0 0 26.928 31.217">
//                                     <g clip-path="url(#clip-path)">
//                                         <path d="M22.72,24.027V21.109a9.445,9.445,0,0,0-6.573-9.044V10.744a2.689,2.689,0,1,0-5.378,0v1.321A9.445,9.445,0,0,0,4.2,21.109v2.918A14.518,14.518,0,0,1,.264,34.011.928.928,0,0,0,.086,35a.9.9,0,0,0,.825.555H9.067a4.465,4.465,0,0,0,8.783,0h8.156A.9.9,0,0,0,26.831,35a.928.928,0,0,0-.178-.992A14.518,14.518,0,0,1,22.72,24.027ZM12.562,10.744a.9.9,0,1,1,1.793,0V11.7c-.295-.029-.594-.045-.9-.045s-.6.015-.9.045Zm.9,26.644a2.7,2.7,0,0,1-2.535-1.829h5.069A2.7,2.7,0,0,1,13.459,37.388Z" transform="translate(0.005 -8)" fill="#545454"/>
//                                         <path className='onAlert' d="M19.2,20.242a.9.9,0,1,0,1.793,0,13.768,13.768,0,0,0-3.938-9.7.884.884,0,0,0-1.268,0,.928.928,0,0,0,0,1.293A11.931,11.931,0,0,1,19.2,20.242Z" transform="translate(5.914 -7.134)"/>
//                                         <path className='onAlert' d="M.911,21.157a.906.906,0,0,0,.9-.914A11.931,11.931,0,0,1,5.22,11.836a.928.928,0,0,0,0-1.293.884.884,0,0,0-1.268,0,13.768,13.768,0,0,0-3.938,9.7A.905.905,0,0,0,.911,21.157Z" transform="translate(0.005 -7.134)"/>
//                                     </g>
//                                 </svg>
//                             </Dropdown.Toggle>
//                             <Dropdown.Menu className="headerDropdown notificationDropdown">
//                                 <span className='notificationHeading'>Notifications</span>
//                                 <ul>
//                                     <li>
//                                         <Link to="/">
//                                             <div className='notificationItem'>
//                                                 <div className="imgProfile">
//                                                     <img src={profile_icons} className="profile_icons" alt="profile_icons"/>
//                                                 </div>
//                                                 <div className='notifcationText'>
//                                                     <span>You've added new task</span>
//                                                     <span className='notificationTime'>23 mins ago</span>
//                                                 </div>
//                                             </div>
//                                         </Link>
//                                     </li>
//                                     <li>
//                                         <Link to="/">
//                                             <div className='notificationItem'>
//                                                 <div className="imgProfile">
//                                                     <img src={profile_icons} className="profile_icons" alt="profile_icons"/>
//                                                 </div>
//                                                 <div className='notifcationText'>
//                                                     <span>You've added new task</span>
//                                                     <span className='notificationTime'>23 mins ago</span>
//                                                 </div>
//                                             </div>
//                                         </Link>
//                                     </li>
//                                     <li>
//                                         <Link to="/">
//                                             <div className='notificationItem'>
//                                                 <div className="imgProfile">
//                                                     <img src={profile_icons} className="profile_icons" alt="profile_icons"/>
//                                                 </div>
//                                                 <div className='notifcationText'>
//                                                     <span>You've added new task</span>
//                                                     <span className='notificationTime'>23 mins ago</span>
//                                                 </div>
//                                             </div>
//                                         </Link>
//                                     </li>
//                                 </ul>
//                             </Dropdown.Menu>
//                         </Dropdown>
//                     </li>
//                     <li>
//                         <Dropdown>
//                             <Dropdown.Toggle className="headerButton profileBtn">
//                                 <div className="imgProfile">
//                                     <img src={profile_icons} className="profile_icons" alt="profile_icons"/>
//                                 </div>
//                             </Dropdown.Toggle>
//                             <Dropdown.Menu className="headerDropdown profileDropdown">
//                                 <ul>
//                                     <li>
//                                         <Link to="/">My Profile</Link>
//                                     </li>
//                                     <li>
//                                         <Link to="/">Settings</Link>
//                                     </li>
//                                     <li>
//                                         <Link to="/">Log Out</Link>
//                                     </li>
//                                 </ul>
//                             </Dropdown.Menu>
//                         </Dropdown>
//                     </li>
//                 </ul>
                
//             </div>
//         </header>
